<template>
  <div>
    <div class="login-cover">
      <div
        class="login-cover-image"
        :style="{
          backgroundImage: 'url(' + require('@/assets/img/bg-login.jpg') + ')',
        }"
      ></div>
      <div class="login" data-pageload-addclass="animated fadeIn">
        <div class="bg-login m-5">
          <div class="login-header">
            <div class="brand text-center">
              <img
                src="../assets/img/logo.png"
                alt="logo"
                style="height: 75px; margin-bottom: 20px"
              />
              <br />
            </div>
          </div>
          <form @submit.prevent="login" method="POST" class="margin-bottom-0">
            <div class="input-field m-b-20">
              <input
                type="text"
                placeholder="Email/Username"
                id="username"
                v-model="username"
                required
              />
              <label>Enter your email/username</label>
            </div>
            <div class="input-field m-b-20">
              <input
                type="password"
                placeholder="Password"
                id="password"
                v-model="password"
                required
              />
              <label>Enter your password</label>
            </div>
            <div class="checkbox checkbox-css m-b-20 text-left text-white">
              <input type="checkbox" id="remember_me_checkbox" value="" />
              <label for="remember_me_checkbox"> Remember Me </label>
            </div>
            <b-alert v-if="alert == 'error'" variant="danger" show fade>
              <strong>Login Gagal!</strong>
              <br />
              <span>{{ message }}</span>
            </b-alert>
            <b-alert v-if="alert == 'success'" variant="success" show fade>
              <strong>Login Success!</strong>
              <br />
              <span>{{ message }}</span>
            </b-alert>
            <div>
              <button class="button" type="submit">
                <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                Log In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
import packageJson from "@/../package.json";

export default {
  data() {
    return {
      username: "",
      password: "",
      loading: false,
      message: "",
      alert: "",
      appName: packageJson.title,
      appDescription: packageJson.description,
      appVersion: packageJson.version,
    };
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    login() {
      this.loading = true;
      this.alert = "";
      const formData = new FormData();
      formData.append("username", this.username);
      formData.append("password", this.password);

      axios
        .post("http://localhost/api-crm-dea/v2/auth/login/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          if (response.data.status == true) {
            this.loading = false;
            this.alert = "success";
            this.message = "Selamat Datang " + response.data.nama;

            setTimeout(() => {
              const userData = {
                id: response.data.id,
                nama: response.data.nama,
                foto: response.data.foto,
                level: response.data.level,
                token: response.data.token,
              };
              localStorage.setItem("userData", JSON.stringify(userData));
              if (response.data.level === "admin") {
                this.$router.push("/dashboard");
              } else if (response.data.level === "guest") {
                this.$router.push("/dashboard");
              } else {
                this.$router.push("/dashboard");
              }
            }, 2000);
          } else {
            this.loading = false;
            this.alert = "error";
            this.message = response.data.message;
            setTimeout(() => {
              this.alert = "";
            }, 2000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style>
.login-cover {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-login {
  border-radius: 8px;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
}
.input-field {
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 15px 0;
}
.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: 16px;
  pointer-events: none;
  transition: 0.15s ease;
}
.input-field input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fff;
}
.input-field input:focus ~ label,
.input-field input:valid ~ label {
  font-size: 0.8rem;
  top: 10px;
  transform: translateY(-120%);
}
.button {
  background: #fff;
  color: #000;
  font-weight: 600;
  border: none;
  padding: 12px 20px;
  cursor: pointer;
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid transparent;
  transition: 0.3s ease;
  width: 100%;
}
.button:hover {
  color: #fff;
  border-color: #fff;
  background: rgba(255, 255, 255, 0.15);
}
</style>